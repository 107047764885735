/* eslint-disable */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ColumnSelector.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  Avatar as MuiAvatar,
  Box,
  Button,
  IconButton,
  Popover as MuiPopover,
  Tooltip,
  Typography,
} from "@mui/material";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
    width: 300px;
  }
`;

const MessageHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

const ButtonBox = styled(Box)`
    border-top: 1px solid ${(props) => props.theme.palette.divider};  
`;

const ColumnSelector = ({ columns, columnsSelected, onColumnSelect }) => {

  const [selectedColumns, setSelectedColumns] = useState(columnsSelected);
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null);
  const { t } = useTranslation();

  const handleColumnSelect = (event) => {
    const columnName = event.target.name;
    const isSelected = event.target.checked;
    const newSelectedColumns = isSelected
      ? [...selectedColumns, columnName]
      : selectedColumns.filter((column) => column !== columnName);
    setSelectedColumns(newSelectedColumns);
  };
  const handleApplyClick = () => {
    onColumnSelect(selectedColumns);
    handleClose();
  };

  const handleOpen = (e) => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button ref={ref} onClick={handleOpen} endIcon={<KeyboardArrowDownIcon />} variant='contained'>Columns</Button>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <MessageHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            Columns
          </Typography>
        </MessageHeader>
        <>
          <FormGroup>
            <div className='gridHeader'>
              {isOpen && columns.map((column) => (
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label={column}
                  name={t(column)}
                  checked={selectedColumns.includes(column)}
                  onChange={handleColumnSelect}
                />
              ))}

            </div>
          </FormGroup>
          <ButtonBox p={1} display="flex" justifyContent="center">
            <Button onClick={handleApplyClick} size="small" component={Link} to="#">
              Apply
            </Button>
          </ButtonBox>
        </>
      </Popover>
    </>
  );
}

export default ColumnSelector;
