/* eslint-disable */
import { useEffect } from "react";
import { useStorage } from "./StorageContext";

const ProductContext = () => {
    const { info, setInfo } = useStorage();
    const currentProduct = location.pathname.split("/")[1];
    useEffect(() => {     
        if (currentProduct !== info.currentProduct) {           
            setInfo({ currentProduct: currentProduct });
        }
    }, [currentProduct]);
};

export default ProductContext;
