/* eslint-disable */
export const userData = {
    nombre: 'Doña',
    apellidos: 'Chelito',
    email: 'chelito@unnax.com',
    telefono: '+34 555 123 456',
    empresa: 'Unnax',
    direccion: 'Calle Falsa 123',
    ciudad: 'Madrid',
    codigoPostal: '28001',
    cif: 'A12345678',
    pais: 'España',
    zonaHoraria: 'GMT+1',
    empresa: {
      nombre: "Chelito's Emporium",
      correoElectronico: "info@chelitosemporium.com",
      direccion: "Calle Falsa 123",
      ciudad: "Madrid",
      codigoPostal: "28001",
      cif: "A12345678",
      telefono: "+34 912 345 678",
    },
    departamentos: {
      finanzas: {
        personaContacto: 'Juan Pérez',
        correo: 'juan.perez@unnax.com',
        telefono: '+34 555 987 654',
      },
      it: {
        personaContacto: 'María Rodríguez',
        correo: 'maria.rodriguez@unnax.com',
        telefono: '+34 555 321 789',
      },
      gestion: {
        personaContacto: 'Carlos Martínez',
        correo: 'carlos.martinez@unnax.com',
        telefono: '+34 555 654 987',
      },
    },
  };
  