/* eslint-disable */
import { CreditCard, Layout, Settings, Tablet } from "react-feather";

const dashboardItems = (t) => {
  const pagesSection = [
    {
      href: "/aggregation/reads",
      icon: Layout,
      title: t("aggregation.menu.aggregation.reads"),
    },
    {
      href: "/aggregation/settings",
      icon: Settings,
      title: t("aggregation.menu.aggregation.settings"),
    },
    {
      href: "/aggregation/table",
      icon: Tablet,
      title: "Tabla ejemplo",
    },
    {
      href: "/aggregation/map",
      icon: Tablet,
      title: "Mapa ESPAÑA",
    },
  ];

  const navItems = [
    {
      title: t("aggregation.menu.aggregation.title"),
      pages: pagesSection,
    },
  ];

  return navItems;
};

export default dashboardItems;
