/* eslint-disable */
import React, { useState } from 'react';

const CheckboxFilterSelector = ({ title, filterName, defaultSelection, elements, setElementSelected }) => {
    const [selected, setSelected] = useState(defaultSelection || []);

    const handleSelection = (event) => {
        const { name } = event.target;
        const newSelected = [...selected];
        if (newSelected.includes(name)) {
            newSelected.splice(newSelected.indexOf(name), 1);
        } else {
            newSelected.push(name);
        }
        setSelected(newSelected);
        setElementSelected({ [filterName]: newSelected });
    };

    return (
        <div style={{marginBottom: '1em', borderBottom:'1px solid rgba(224, 224, 224, 1)', paddingBottom: '0.8em'}}>
             <p style={{fontWeight:100}}>{title}</p>
            {elements && elements.map((element) => (
                <label style={{marginRight: '0.5em'}} key={element}>
                    <input
                        type="checkbox"
                        name={element}
                        value={element}
                        checked={selected.includes(element)}
                        onChange={handleSelection}
                    />
                    <span style={{fontWeight:100, fontSize: '12px'}}>{element}</span>
                </label>
            ))}
        </div>
    );
}

export default CheckboxFilterSelector;

