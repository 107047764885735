import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children, toHome = false }) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  // Mirar si el usuario tiene permisos/productos para acceder a la ruta en cuestión

  if (!toHome & isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  } else if (toHome && isInitialized && isAuthenticated) {
    return <Navigate to="/products" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
