/* eslint-disable */
import React, { useState } from 'react';
import styled from "@emotion/styled";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";
import ColumnSelector from './ColumnSelector';
import FilterSelector from 'pages/filters/FilterSelector';

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: black;
    color: white;
  }
  border: none;
  
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const CustomRow = styled(TableRow)`
  cursor: pointer;
`;

const CustomPaper = styled(MuiPaper)`
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  width: 95%;
  margin: 2em auto;
`;

const TableWithData = ({ tableData = [], defaultHeaderSelected = [], nonAvailableHeaders = [], filters = [], defaultFilterSelection = [], handlerFilterChanges = () => { } }) => {
  console.log('defaultFilterSelection:', defaultFilterSelection);
  const [rows,] = useState(tableData);
  const [header,] = useState(Object.keys(rows[0] || []).filter(element => !nonAvailableHeaders.includes(element)));
  const [headerSelected, setHeaderSelected] = useState(defaultHeaderSelected);
  const [rowsOpens, setRowsOpens] = useState({});

  const Card = styled(MuiCard)(spacing);
  const CardContent = styled(MuiCardContent)(spacing);


  const handleSelectionColumn = (headerSelection) => {
    setHeaderSelected(headerSelection);
  }
  const handleOpenRow = (index) => {
    const newRowsOpens = { ...rowsOpens };
    newRowsOpens[index] = !newRowsOpens[index];
    setRowsOpens(newRowsOpens);
  }

  return (
    <Card mb={6} >
      <CardContent pb={1}>
        <Typography variant="h6" gutterBottom>
          Tabla de ejemplo para movimientos :-)
        </Typography>
        {(filters?.length > 0) &&
          <div style={{ float: 'right', display: "flex", width: "190px", justifyContent: "space-between" }}>
            <FilterSelector
              filters={filters}
              defaultSelection={defaultFilterSelection}
              setFilterChange={handlerFilterChanges} />
            <ColumnSelector columns={header} columnsSelected={headerSelected} onColumnSelect={handleSelectionColumn} />
          </div>}
      </CardContent>

      <div style={{ width: '100%', overflowX: 'auto' }}>
        <CustomPaper>
          <Table stickyHeader>
            <TableHead>
              <TableRow key={'header'}>
              <TableCell />
                {headerSelected.map((element, index) => {
                  return <TableCell style={{fontWeight: 700, color: "rgb(86 86 86)"}} key={'header-' + index}> {element}</TableCell>
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.map((row, index) => (
                <>
                  {/* selected visible elements*/}
                  <CustomRow hover role="checkbox" style={{ background: (rowsOpens[index] && header.length > headerSelected.length) && "rgb(29 91 128 / 20%)" }} key={index} onClick={() => handleOpenRow(index)} >
                    <TableCell style={{width: "7%", borderBottom: "1px solid rgba(0, 0, 0, 0.09)" }}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                      >
                        {(rowsOpens[index] && header.length > headerSelected.length) ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                      </IconButton>
                    </TableCell>
                    {headerSelected.map((element, index2) => {
                      return <TableCell style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.09)" }} key={index + '-' + index2}> {row[element]}</TableCell>
                    })}
                  </CustomRow>
                  {/* hidden element on expandible row*/}
                  {(rowsOpens[index] && header.length > headerSelected.length) &&
                    <TableRow onClick={() => handleOpenRow(index)}>

                      <TableCell colSpan={headerSelected.length}>
                        <Table id= "expanded-table" style={{width: "100%"}}>
                          <TableBody >
                            {header.map(element => {
                              if (!headerSelected.includes(element)) {
                                return (
                                  <CustomTableRow>
                                    <CustomTableCell>
                                      {element}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                      {row[element]}
                                    </CustomTableCell>
                                  </CustomTableRow>
                                )
                              }
                            })}

                          </TableBody>
                        </Table>
                      </TableCell>

                    </TableRow>
                  }
                </>
              ))
              }
            </TableBody>
          </Table>
        </CustomPaper>
      </div>

    </Card>
  );
}

export default TableWithData;
