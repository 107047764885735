import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 50%;
  height: 84px;
  margin-bottom: 10px;
`;

const ContainerLogo = styled.div`
  text-align: center;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  border-radius: 15px 15px 15px 15px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
  @media (max-width: 550px) {
    margin: 2em;
  }
`;

/* const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;
 */
function SignIn() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {/* <div style={{ display: "flex" }}> */}
      <Wrapper>
        <Helmet title={t("login.head")} />
        {/* <BigAvatar alt="Chelito" src="/static/img/avatars/avatar-1.jpg" /> */}
        <ContainerLogo>
          <Brand />
        </ContainerLogo>
        <Typography component="h1" variant="h2" align="center" gutterBottom>
          {t("login.title")}
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          {t("login.subtitle")}
        </Typography>
        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
