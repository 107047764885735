/* eslint-disable */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translations } from "locales/translation";

i18n.use(initReactI18next).init({
  resources: translations(),
  lng: localStorage.getItem('i18nextLng') || /^es\b/.test(navigator.language) ? "es" : "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
