/* eslint-disable */
import { Button } from '@mui/material';
import React, { useState, useRef } from 'react';
import CheckboxFilterSelector from './CheckboxFilterSelector';
import DateFilterSelector from './DateFilterSelector';
import DateRangeFilterSelector from './DateRangeFilterSelector';
import RadioFilterSelector from './RadioFilterSelector';
import TextboxFilterSelector from './TextboxFilterSelector';
import styled from "@emotion/styled";
import FormGroup from '@mui/material/FormGroup';
import { Link } from "react-router-dom";



import {
    Avatar as MuiAvatar,
    Box,
    Popover as MuiPopover,
    Typography,
  } from "@mui/material";
  
  const Popover = styled(MuiPopover)`
    .MuiPaper-root {
      ${(props) => props.theme.shadows[1]};
      border: 1px solid ${(props) => props.theme.palette.divider};
      width: 300px;
    }
  `;
  
  const MessageHeader = styled(Box)`
    text-align: center;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  `;
  
  const ButtonBox = styled(Box)`
      border-top: 1px solid ${(props) => props.theme.palette.divider};
    }
  `;


const FilterSelector = ({ filters = [], defaultSelection = [], setFilterChange }) => {
    const [open, setOpen] = useState(false)
    const [filterSelection, setFilterSelection] = useState(defaultSelection);
    const ref = useRef(null);
    
    const handleChanges = (newSelection) => {
        console.log('filterSelection:', filterSelection);
        console.log("handleChanges >>" + JSON.stringify(newSelection));
        setFilterSelection({ ...filterSelection, ...newSelection });
    }
    
    const handleChangeFilter = () => {
        console.log("handleChangeFilter >> ", filterSelection);
        setOpen(false);
        setFilterChange(filterSelection);
    }
    
    const getFilterByType = (filterData) => {
        const name = filterData.filterName;
        switch (filterData.filterType) {
            case 'textbox':
                return <TextboxFilterSelector
                    title={filterData.text}
                    filterName={filterData.filterName}
                    placeHolder={filterData.placeHolder}
                    defaultSelection={filterSelection[name]}
                    setSelection={handleChanges} />
            case 'textboxAuto':
                return <TextboxFilterSelector
                    title={filterData.text}
                    filterName={filterData.filterName}
                    placeHolder={filterData.placeHolder}
                    auto={true}
                    setSelection={handleChanges} />
            case 'radio':
                return <RadioFilterSelector
                    title={filterData.text}
                    filterName={filterData.filterName}
                    selectionElements={filterData.options}
                    defaultSelection={filterSelection[name]}
                    setSelection={handleChanges} />
            case 'checkbox':
                return <CheckboxFilterSelector
                    title={filterData.text}
                    filterName={filterData.filterName}
                    elements={filterData.options}
                    defaultSelection={filterSelection[name]}
                    setElementSelected={handleChanges} />
            case 'date':
                return <DateFilterSelector
                    title={filterData.text}
                    filterName={filterData.filterName}
                    defaultSelection={filterSelection[name]}
                    setDateSelected={handleChanges} />
            case 'dateRange':
                return <DateRangeFilterSelector
                    title={filterData.text}
                    filterName={filterData.filterName}
                    defaultSelection={filterSelection[name]}
                    setDateRangeSelected={handleChanges} />
            default:
                return <TextboxFilterSelector
                    title={filterData.text}
                    filterName={filterData.filterName}
                    setSelection={handleChanges} />
        }
    };


    return (
        <React.Fragment>
          <Button ref={ref} onClick={() => setOpen(true)} variant='contained'>Filters</Button>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={open}
      >
        <MessageHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
          Filters
          </Typography>
        </MessageHeader>
        <React.Fragment>
        <FormGroup>
      <div className='gridHeader'>
        {open && <>
                    {
                        filters.map((filterData) => {
                            return (
                                <div>
                                    {getFilterByType(filterData)}
                                </div>
                            )
                        })
                    }
                </>
        }
         
      </div>
    </FormGroup>
          <ButtonBox p={1} display="flex" justifyContent="center">
            <Button onClick={()=>handleChangeFilter()} size="small" component={Link} to="#">
              Apply
            </Button>
          </ButtonBox>
        </React.Fragment>
      </Popover>
    </React.Fragment>
    // from here
    );
};


export default FilterSelector; 