/* eslint-disable*/
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import Popover from "@mui/material/Popover";


export default function DateQuickSelector({ anchor, isOpen, onClose, handleCalendar }) {
    const day = 24 * 60 * 60 * 1000;
    const quickSelection = [
        { id: 0, name: "Hoy", value: { start: new Date(), end: new Date() } },
        { id: 1, name: "Ayer", value: { start: new Date(Date.now() - day), end: new Date() } },
        { id: 2, name: "Esta semana", value: { start: new Date(Date.now() - 7 * day), end: new Date() } },
        { id: 3, name: "Mes actual", value: { start: new Date(Date.now() - 30 * day), end: new Date() } },
        { id: 4, name: "Mes pasado", value: { start: new Date(Date.now() - 60 * day), end: new Date(Date.now() - 30 * day) } }
    ]

    const handleListItemClick = (selection) => {
        onClose(selection.value);
    };

    return (
        <Popover
            id={"date-quick-selector"}
            open={isOpen}
            anchorEl={anchor}
            onClose={() => onClose(null)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <Typography sx={{ p: 2 }}>Selecciona una opción</Typography>
            <List sx={{ pt: 0 }}>
                {quickSelection.map((selection) => <>
                    <ListItem disableGutters>
                        <ListItemButton onClick={() => handleListItemClick(selection)} key={selection.id}>
                            <ListItemText primary={selection.name} />
                        </ListItemButton>
                    </ListItem>
                    <Divider light />
                </>
                )}

                <ListItem disableGutters>
                    <ListItemButton
                        key={"calendar"}
                        autoFocus
                        onClick={() => handleCalendar()}
                    >
                        <ListItemText primary="Abrir calendario" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Popover>        
    );
}












