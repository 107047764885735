import React from "react";
import async from "components/Async";
// Guards
import AuthGuard from "components/guards/AuthGuard";
// Dashboard components
import DashboardLayout from "layouts/Dashboard";
const Default = async(() => import("pages/dashboards/Default"));

const routes = [
  {
    path: "id",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Default />,
      },
      {
        path: "default",
        element: <Default />,
      },
      {
        path: "verifications",
        element: <Default />,
      },
      {
        path: "settings",
        element: <Default />,
      },
    ],
  },
];

export default routes;
