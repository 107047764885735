// Themes
export const THEMES = Object.freeze({
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
});
// Permissions
export const Actions = Object.freeze({
  READ: "read",
  EDIT: "edit",
  CREATE: "create",
  DELETE: "delete",
});
