import React from "react";
import SelectProduct from "./SelectProduct";
import Navbar from "components/navbar/Navbar";

function Presentation() {
  return (
    <>
      <Navbar onDrawerToggle={null} />
      <SelectProduct />
    </>
  );
}

export default Presentation;
