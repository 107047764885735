/* eslint-disable */
import { CreditCard, Layout } from "react-feather";

const dashboardItems = (t) => {

  const pagesSection = [
    {
      href: "/payments/movements",
      icon: CreditCard,
      title: t("payments.menu.payments.movements"),
    },
    {
      href: "/payments/settings",
      icon: Layout,
      title: t("payments.menu.payments.settings"),
    },
  ];

  const navItems = [
    {
      title: t("payments.menu.payments.title"),
      pages: pagesSection,
    },
  ];
  return navItems;
};

export default dashboardItems;
