/* eslint-disable */
import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import DateQuickSelector from './DateQuickSelector';


const DateRangeFilterSelector = ({ title, filterName, defaultSelection, setDateRangeSelected }) => {
  const [value, setValue] = React.useState(defaultSelection || { start: new Date(), end: new Date() });
  const states = { none: -1, selector: 0, first: 1, second: 2 };
  const [state, setState] = React.useState(states.none)
  const anchor = React.useRef(null);


  const notifyChanges = () => {
    setDateRangeSelected({ [filterName]: value });
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{ start: 'desde', end: 'hasta' }}
    >
      <p style={{fontWeight:100, marginBottom: 0}}>{title}</p>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '1em', borderBottom:'1px solid rgba(224, 224, 224, 1)', paddingBottom: '1em'}}>
        <DateQuickSelector
          anchor={anchor?.current}
          isOpen={state === states.selector}
          onClose={(value) => {
            if (value) 
              setValue(value);
              setState(states.none);
            
          }}
          handleCalendar={() => setState(states.first)}
        />
        <div >
        <p  ref={anchor} style={{fontWeight:100, fontSize: '12px', marginBottom:0}}>desde</p>
          <DatePicker
            open={state == states.first}
            onOpen={() => setState(states.selector)}
            disableFuture={true}
            value={value.start}
            onChange={(newStartValue) => {
              setValue({ ...value, start: newStartValue.toISOString() });
            }}
            onClose={() => setState(states.second)}
            renderInput={(params) => <TextField {...params} />}
            //inputFormat="dd/MM/yyyy"
            mask="__/__/____"
            openTo="day"
            views={['year', 'month', 'day']}
          />
        </div>
        <div>
        <p style={{fontWeight:100, fontSize: '12px', marginBottom:0}}>hasta</p>
          <DatePicker
            minDate={value.start}
            disableFuture={true}
            open={state == states.second}
            onOpen={() => setState(state == states.second ? states.second : states.selector)}
            onClose={() => setState(states.none)}
            value={value.end}
            onChange={(newStartValue) => {
              setValue({ ...value, end: newStartValue.toISOString() });
              notifyChanges();
            }}
            renderInput={(params) => <TextField {...params} />}

            mask="__/__/____"
            openTo="day"
            views={['year', 'month', 'day']}
            selectedSections={['year', 'month', 'day']}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
}


export default DateRangeFilterSelector;