import { CreditCard, Layout } from "react-feather";

const dashboardItems = (t) => {
  const pagesSection = [
    // {
    //   href: "/wallets",
    //   icon: Layout,
    //   title: "Wallets",
    //   children: [
    {
      href: "/wallets/cosas",
      icon: Layout,
      title: t("wallets.menu.wallets.things"),
    },
    {
      href: "/wallets/settings",
      icon: Layout,
      title: t("wallets.menu.wallets.settings"),
    },
  ];

  const navItems = [
    {
      title: t("wallets.menu.wallets.title"),
      pages: pagesSection,
    },
  ];

  return navItems;
};

export default dashboardItems;
