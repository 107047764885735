import React from "react";

import { Navigate } from "react-router-dom";

//import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import PresentationLayout from "./layouts/Presentation";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Landing
import Landing from "./pages/presentation/Landing";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

//profile
import UserProfile from "components/profile/UserProfile";

//invoices
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";

// Products
import { productRoutes } from "products";
import Dashboard from "layouts/Dashboard";
import UserProfileView from "components/profile/UsersProfileView";

// Dashboard components
//const Default = async(() => import("./pages/dashboards/Default"));
/* eslint-disable */
const routes = [
  {
    path: "/",
    element: <Navigate to={`/products`} />,
  },
  {
    path: "/products",
    element: (
      <AuthGuard>
        <PresentationLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Landing />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <AuthGuard toHome> <SignIn /> </AuthGuard>,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <AuthGuard toHome> <ResetPassword /> </AuthGuard>,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "administration/profile",
    element:
      <AuthGuard>
        <PresentationLayout />
      </AuthGuard>,
    children: [
      {
        path: "",
        element:
          <AuthGuard>
            <Dashboard>
              <UserProfile />
            </Dashboard>
          </AuthGuard>
      },
      {
        path: "users",
        element:
          <AuthGuard>
            <Dashboard>
              <UserProfileView />
            </Dashboard>
          </AuthGuard>
      },
    ],
  },
  {
    path: "administration/invoices",
    element:
      <AuthGuard>
        <Dashboard />
      </AuthGuard>,
    children: [
      {
        path: "list",
        element:
          <AuthGuard>
            <InvoiceList />
          </AuthGuard>,
      },
      {
        path: "detail",
        element:
          <AuthGuard>
            <InvoiceDetails />
          </AuthGuard>,
      },
    ],
  },
  ...productRoutes,
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

/*   {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  } */

export default routes;
