/* eslint-disable */
import React from "react";
import styled from "@emotion/styled";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import { THEMES } from "../../../constants";
import { useStorage } from "contexts/StorageContext";
import Variant from "./Variant";

const Wrapper = styled.div`
  ${spacing};
  // background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
  border-top: 2px solid rgba(233, 245, 244, 0.8);
  justify-content: center;
  display: flex;
  min-height: 80%;
  align-items: center;
`;


const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: 2em;
  @media screen and (min-width: 960px) {
    margin-bottom: 1.4em !important
  }
  
`;

const TypographyTitle = styled(Typography)`
  font-size: 1.4em;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  @media screen and (min-width: 960px) {
    margin-bottom: 2em !important
  }
`;


const AvailableProducts = ({ t }) => {
	const { info } = useStorage();
	const theme = THEMES.DEFAULT; //THEMES[info.user.theme]

	return (
		<Grid justifyContent="center" container spacing={8}>
			{info?.user?.availableProducts.map((product) => (
				<Variant
					theme={theme}
					img={`${product}3`}
					title={t(`products.${product}`)}
					product={product}
					lg={2}
					pxContext={2}
					fontSize={"15px"}
					xs={6}
					borderRadius={"20px"}
					sm={4}
					md={2}
				/>
			))}
		</Grid>
	);
};

function SelectProduct() {
	const { t } = useTranslation();
	return (
		<Wrapper pt={16} pb={20} id="selectProduct">
			<Container>
				<TypographyOverline variant="body2" gutterBottom>
					{t("products.title")}
				</TypographyOverline>
				<TypographyTitle variant="h2" component="h3" gutterBottom>
					{t("products.subtitle")}
				</TypographyTitle>
				<Box mb={8} />
				{/* eslint-disable */}
				<AvailableProducts t={t} />
			</Container>
		</Wrapper>
	);
}

export default SelectProduct;
