/* eslint-disable */
import React, { useState, useCallback } from 'react';

const RadioFilterSelector = ({ title, filterName, defaultSelection, selectionElements, setSelection }) => {
    const [selected, setSelected] = useState(defaultSelection || selectionElements[0]);
    const handleSelection = useCallback((event) => {
        const newSelected = event.target.value;
        setSelected(newSelected);
        setSelection({ [filterName]: newSelected });
    }, [setSelection]);
    return (
        <div style={{marginBottom: '1em', borderBottom:'1px solid rgba(224, 224, 224, 1)', paddingBottom: '0.8em'}}>
            <p style={{fontWeight:100}}>{title}</p>
            {selectionElements && selectionElements.map((selectionElement) => (
                <label style={{marginRight: '0.5em'}} key={selectionElement}>
                    <input
                        type="radio"
                        name="filter"
                        value={selectionElement}
                        checked={selected === selectionElement}
                        onChange={handleSelection}
                    />
                    <span style={{fontWeight:100, fontSize: '12px'}}>{selectionElement}</span>
                </label>
            ))}
        </div>
    );
}

export default RadioFilterSelector;