import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import ResetPasswordComponent from "../../components/auth/ResetPassword";
import { useTranslation } from "react-i18next";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 50%;
  height: 84px;
  margin-bottom: 10px;
  margin-top: -1.2em;
`;

const ContainerLogo = styled.div`
  text-align: center;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  border-radius: 15px 15px 15px 15px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
  @media (max-width: 550px) {
    margin: 2em;
  }
`;

function ResetPassword() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title={t("password.title")} />
        <ContainerLogo>
          <Brand />
        </ContainerLogo>
        <Typography component="h1" variant="h2" align="center" gutterBottom>
          {t("password.title")}
        </Typography>
        <Typography
          style={{ marginBottom: "1.5em" }}
          component="h2"
          variant="body1"
          align="center"
        >
          {t("password.mail")}
        </Typography>

        <ResetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
