/* eslint-disable */
import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Box, Chip, Container, Grid, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { THEMES } from "../../../constants";
import useTheme from "../../../hooks/useTheme";
import { useStorage } from "contexts/StorageContext";

const ProductContext = styled.div(spacing);

const ProductLink = styled.div`
  cursor: pointer;
`;

const ProductImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 4px 12px 0 rgba(18, 38, 63, 0.125);
  transition: 0.15s ease-in-out;
//   border-radius: : ${(props) => props.borderRadius};

  &:hover {
    transform: scale(1.0325);
  }
`;

const ProductChip = styled(Chip)`
  background-color: ${(props) => props.theme.palette.secondary.main};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-top: -16px;
  padding: 3px 0;
  text-align: center;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
`;

const GridProduct = styled(Grid)`
@media screen and (min-width: 960px) {
  padding-left: 20px !important
}
`
const TypographyTitles = styled(Typography)`
text-align: center;
color: ${(props) => props.theme.palette.primary.main};
font-weight: ${(props) => props.theme.typography.fontWeightBold};
}
`

const Variant = ({ theme, title, img, product, isNew = false, lg, pxContext, pb, fontSize, xs, sm, md, borderRadius, handleClose = () => { } }) => {
	const navigate = useNavigate();
	const { setTheme } = useTheme();
	const { setInfo } = useStorage();

	const toggleProduct = (theme, currentProduct) => {
		setTheme(theme);
		setInfo({ currentProduct });
		navigate(`/${currentProduct}`);
		handleClose();
	};

	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg}>
			<ProductContext px={pxContext} pb={pb} >
				<ProductLink onClick={() => toggleProduct(theme, product)}>
					<ProductImage
						style={{ borderRadius }}
						alt={`${title}`}
						src={`/static/img/screenshots/${img}.svg`}
					/>
				</ProductLink>
				<Box mb={3} />
				<TypographyTitles fontSize={fontSize}>
					{title} {isNew && <ProductChip label="New" />}
				</TypographyTitles>
			</ProductContext>
		</Grid>
	);
};


export default Variant;
