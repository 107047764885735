/* eslint-disable */
import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tab,
  Tabs,
  Paper,
  Avatar,
  Grid,
  InputAdornment,
  IconButton
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import { userData } from './mockData';
import EditIcon from '@mui/icons-material/Edit';


const UserProfile = () => {
  const [tabValue, setTabValue] = useState(0);
  const { user } = useAuth();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index } = props;
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  const [editingField, setEditingField] = useState(null);

  const makeFieldEditable = (fieldName) => {
    setEditingField(fieldName);
  };

  const renderEditableField = (fieldName, label, value) => (
    <TextField
      fullWidth
      label={label}
      margin="normal"
      defaultValue={value}
      InputProps={{
        readOnly: editingField !== fieldName,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={() => makeFieldEditable(fieldName)}
            >
              <EditIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <Container maxWidth="md">
      <Box mt={4}>
        <Paper>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Perfil" />
            <Tab label="Empresa" />
            <Tab label="Comunicación" />
          </Tabs>
        </Paper>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={4}>
            <Box display="flex" justifyContent="center" mb={2}>
              <Avatar
                alt="Foto del usuario"
                src={user?.avatar}
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TabPanel value={tabValue} index={0}>
              <Box mt={2}>
                <Typography variant="h6">Perfil de usuario</Typography>
                {renderEditableField('nombre', 'Nombre', userData.nombre)}
                {renderEditableField('apellidos', 'Apellidos', userData.apellidos)}
                {renderEditableField('telefono', 'Número de teléfono', userData.telefono)}
                <Button variant="contained" color="primary" mt={2}>
                  Actualizar perfil
                </Button>
              </Box>
              <Box mt={2}>
                <Typography variant="h6">Cambio de contraseña</Typography>
                <TextField
                  fullWidth
                  type="password"
                  label="Contraseña actual"
                  margin="normal"
                />
                <TextField
                  fullWidth
                  type="password"
                  label="Nueva contraseña"
                  margin="normal"
                />
                <TextField
                  fullWidth
                  type="password"
                  label="Confirmar nueva contraseña"
                  margin="normal"
                />
                <Button variant="contained" color="primary" mt={2}>
                  Cambiar contraseña
                </Button>
              </Box>

            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Box mt={2}>
                <Typography variant="h6">Información de la empresa</Typography>
                {renderEditableField('nombreEmpresa', 'Nombre de la empresa', userData.empresa.nombre)}
                {renderEditableField('correoElectronico', 'Correo electrónico', userData.empresa.correoElectronico)}
                {renderEditableField('direccion', 'Dirección', userData.empresa.direccion)}
                {renderEditableField('ciudad', 'Ciudad', userData.empresa.ciudad)}
                {renderEditableField('codigoPostal', 'Código postal', userData.empresa.codigoPostal)}
                {renderEditableField('cif', 'CIF', userData.empresa.cif)}
                {renderEditableField('telefonoEmpresa', 'Número de teléfono', userData.empresa.telefono)}

                <FormControl fullWidth margin="normal">
                  <InputLabel>País</InputLabel>
                  <Select defaultValue={userData.pais}>
                    <MenuItem value={userData.pais}>{userData.pais}</MenuItem>
                    {/* Agrega tus opciones de países aquí */}
                    <MenuItem value="pais1">País 1</MenuItem>
                    <MenuItem value="pais2">País 2</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Zona horaria</InputLabel>
                  <Select defaultValue={userData.zonaHoraria}>
                    <MenuItem value={userData.zonaHoraria}>{userData.zonaHoraria}</MenuItem>
                    {/* Agrega tus opciones de zonas horarias aquí */}
                    <MenuItem value="zona1">Zona horaria 1</MenuItem>
                    <MenuItem value="zona2">Zona horaria 2</MenuItem>
                  </Select>
                </FormControl>
                <Button variant="contained" color="primary" mt={2}>
                  Actualizar
                </Button>
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Box mt={2}>
                <Typography variant="h6">Departamento de Finanzas</Typography>
                {renderEditableField('personaContactoFinanzas', 'Persona de contacto', userData.departamentos.finanzas.personaContacto)}
                {renderEditableField('correoFinanzas', 'Correo', userData.departamentos.finanzas.correo)}
                {renderEditableField('telefonoFinanzas', 'Número de teléfono', userData.departamentos.finanzas.telefono)}
              </Box>
              <Box mt={2}>
                <Typography variant="h6">Departamento de IT</Typography>
                {renderEditableField('personaContactoIT', 'Persona de contacto', userData.departamentos.it.personaContacto)}
                {renderEditableField('correoIT', 'Correo', userData.departamentos.it.correo)}
                {renderEditableField('telefonoIT', 'Número de teléfono', userData.departamentos.it.telefono)}
              </Box>
              <Box mt={2}>
                <Typography variant="h6">Departamento de Gestión</Typography>
                {renderEditableField('personaContactoGestion', 'Persona de contacto', userData.departamentos.gestion.personaContacto)}
                {renderEditableField('correoGestion', 'Correo', userData.departamentos.gestion.correo)}
                {renderEditableField('telefonoGestion', 'Número de teléfono', userData.departamentos.gestion.telefono)}
              </Box>
              <Box mt={2}>
                <Button variant="contained" color="primary">
                  Actualizar
                </Button>
              </Box>
            </TabPanel>
          </Grid>
        </Grid>

      </Box>
    </Container>
  );
};

export default UserProfile;




