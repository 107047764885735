/* eslint-disable */
import aggregationEs from "products/aggregation/translation/es.json";
import idEs from "products/id/translation/es.json";
import paymentsEs from "products/payments/translation/es.json";
import walletsEs from "products/wallets/translation/es.json";

import aggregationEn from "products/aggregation/translation/en.json";
import idEn from "products/id/translation/en.json";
import paymentsEn from "products/payments/translation/en.json";
import walletsEn from "products/wallets/translation/en.json";

import translationEs from "./es.json";
import translationEn from "./en.json";


export const translations = (product) => {
    return {
        es: {
            translation: {
                ...translationEs,
                aggregation: aggregationEs,
                id: idEs,
                payments: paymentsEs,
                wallets: walletsEs
            }
        }, en: {
            translation: {
                ...translationEn,
                aggregation: aggregationEn,
                id: idEn,
                payments: paymentsEn,
                wallets: walletsEn
            }
        }
    }

};
