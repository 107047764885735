// Routes
import AggregationRouter from "./aggregation/routes";
import PaymentsRouter from "./payments/routes";
import WalletsRouter from "./wallets/routes";
import IdRouter from "./id/routes";
// DashboardItems
import AggregationDashboardItems from "./aggregation/dashboardItems";
import PaymentsDashboardItems from "./payments/dashboardItems";
import WalletsDashboardItems from "./wallets/dashboardItems";
import IdDashboardItems from "./id/dashboardItems";
/* eslint-disable */

// Names
const PRODUCTS = {
    AGGREGATION: "aggregation",
    PAYMENTS: "payments",
    WALLETS: "wallets",
    ID: "id",
};

const productRoutes = [...AggregationRouter, ...PaymentsRouter, ...WalletsRouter, ...IdRouter];

const getAvailableItems = (currentProduct, t) => {
    switch (currentProduct) {
        case PRODUCTS.AGGREGATION:
            return AggregationDashboardItems(t);
        case PRODUCTS.PAYMENTS:
            return PaymentsDashboardItems(t);
        case PRODUCTS.WALLETS:
            return WalletsDashboardItems(t);
        case PRODUCTS.ID:
            return IdDashboardItems(t);
        default:
            return [];
    }
}


export { productRoutes, getAvailableItems };
