/* eslint-disable */
import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import Data from '../data.json';
import TableWithData from '../TableWithData';

const ExampleTableWithFilter = () => {

    // this is the state that will hold the filter selection
    const [filterSelection, setFilterSelection] = useState([]);


    // set the filter you want to use
    // the value will be returned in the handlerFilterChanges function with the name of the filter, filterName
    // text is the text that will be shown in the filter selector title, optional
    // placeHolder is the placeholder of the textbox, optional
    // options is the array of options for the radio and checkbox
    const [filters, setFilters] = useState([
        {
            filterName: 'textbox',
            text: 'Textbox - aplica al hacer click en el boton',
            filterType: 'textbox',
            placeHolder: 'Escribe algo',
        },
        {
            filterName: 'account',
            text: 'textboxAuto - lo que escribas se busca :)',
            filterType: 'textboxAuto',
            placeHolder: 'Escribe algo',
        },
        {
            filterName: 'radio',
            text: 'radio',
            filterType: 'radio',
            options: ['uno', 'dos', 'tres']
        },
        {
            filterName: 'checkbox',
            text: 'checkbox',
            filterType: 'checkbox',
            options: ['uno', 'dos', 'tres']
        },
        {
            filterName: 'date',
            text: 'date',
            filterType: 'date',
        },
        {
            filterName: 'dateRange',
            text: 'dateRange',
            filterType: 'dateRange',
        }
    ]);

    const handlerFilterChanges = (newFilters) => {
        console.log('newFilters:', newFilters);
       setFilterSelection(newFilters);
    }


    return (
        <TableWithData
            tableData={Data.movements}
            defaultHeaderSelected={['order_code', 'amount', 'concept', 'state']} // headers that will be shown in the table by default
            nonAvailableHeaders={['gateway_fraud_score']} // headers that will not be shown in the header selector or the table
            filters={filters}
            defaultFilterSelection={filterSelection}
            handlerFilterChanges={handlerFilterChanges}
        />

    )
}

export default ExampleTableWithFilter;