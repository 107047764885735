/*eslint-disable*/
const usersMockData = [
    {
      name: 'John',
      surname: 'Doe',
      role: 'Admin',
      email: 'johndoe@example.com',
      phone: '555-1234',
      lastAccess: '2023-04-17',
    },
    {
      name: 'Jane',
      surname: 'Doe',
      role: 'User',
      email: 'janedoe@example.com',
      phone: '555-5678',
      lastAccess: '2023-04-16',
    },
    {
      name: 'Bob',
      surname: 'Smith',
      role: 'User',
      email: 'bobsmith@example.com',
      phone: '555-9876',
      lastAccess: '2023-04-15',
    },
    {
      name: 'Alice',
      surname: 'Johnson',
      role: 'User',
      email: 'alicejohnson@example.com',
      phone: '555-5555',
      lastAccess: '2023-04-14',
    },
    {
      name: 'Frank',
      surname: 'Brown',
      role: 'User',
      email: 'frankbrown@example.com',
      phone: '555-2222',
      lastAccess: '2023-04-13',
    },
    {
      name: 'Amy',
      surname: 'Lee',
      role: 'Admin',
      email: 'amylee@example.com',
      phone: '555-3333',
      lastAccess: '2023-04-12',
    },
    {
      name: 'Michael',
      surname: 'Davis',
      role: 'User',
      email: 'michaeldavis@example.com',
      phone: '555-4444',
      lastAccess: '2023-04-11',
    },
    {
      name: 'Lisa',
      surname: 'Brown',
      role: 'User',
      email: 'lisabrown@example.com',
      phone: '555-6666',
      lastAccess: '2023-04-10',
    },
    {
      name: 'David',
      surname: 'Lee',
      role: 'Admin',
      email: 'davidlee@example.com',
      phone: '555-7777',
      lastAccess: '2023-04-09',
    },
    {
      name: 'Samantha',
      surname: 'Jones',
      role: 'User',
      email: 'samanthajones@example.com',
      phone: '555-8888',
      lastAccess: '2023-04-08',
    },
  ];
  
  export default usersMockData;