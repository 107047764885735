/* eslint-disable */
import React, { useState, useEffect, useCallback, } from 'react';
import { ZoomIn } from '@mui/icons-material';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';

const TextboxFilterSelector = ({ title, filterName, setSelection, defaultSelection, auto = false }) => {
    const [value, setValue] = useState(defaultSelection || '');
    const timeout = 3000;
    const [timer, setTimer] = useState(null)

    const handleChange = useCallback((e) => {
        setValue(e.target.value);
    }, []);

    const handleApply = useCallback(() => {
        setSelection({ [filterName]: value });
    }, [filterName, setSelection, value]);

    useEffect(() => {
        if (auto) {
            if (timer) clearTimeout(timer);
            setTimer(setTimeout(() => {
                handleApply();
            }, timeout));
            return () => clearTimeout(timer);
        }
    }, [value])


    return (
        <div style={{marginBottom: '1em', borderBottom:'1px solid rgba(224, 224, 224, 1)', paddingBottom: '0.8em'}}>
            <p style={{marginBottom: 0, fontWeight:100 }}>{title}</p>
            <TextField style={{width:'100%'}} id="outlined-basic" label="Outlined" variant="standard"  value={value} onChange={handleChange} defaultValue="Default Value"/>
            {!auto &&
                <Button variant="outlined" style={{width: '100%', marginTop:'0.5em'}}
                    onClick={handleApply}> Apply </Button>}
        </div>
    );
}


export default TextboxFilterSelector;