import React, { useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useStorage } from "contexts/StorageContext";
import Variant from "pages/presentation/Landing/Variant";
import { THEMES } from "constants";

import {
  Badge,
  Box,
  IconButton,
  Popover as MuiPopover,
  Tooltip,
  Typography,
} from "@mui/material";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const MessageHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

const ImgMenu = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  padding: 0;
  box-shadow: 0 4px 12px 0 rgba(18, 38, 63, 0.125);
`;

const IconMenu = styled(IconButton)`
  padding: 0;
`;

const getElementsOnRowByQuantity = (quantity) => {
  if (quantity % 2 === 0) {
    return 6;
  } else if (quantity % 3 === 0 || quantity % 5 === 0 || quantity % 7 === 0) {
    return 4;
  } else {
    return 12;
  }
};

const AvailableProducts = ({ handleClose }) => {
  const { info } = useStorage();
  const { t } = useTranslation();
  const theme = THEMES.DEFAULT; //THEMES[info.user.theme]
  const elementsOnRow = getElementsOnRowByQuantity(
    info?.user?.availableProducts?.length
  );
  const available = info?.user?.availableProducts?.length > 1;

  return (
    <Grid container spacing={2}>
      {!available && (
        <h2 style={{ textAlign: "center" }}>
          {t("no_more_products_available")}
        </h2>
      )}
      {available &&
        info?.user?.availableProducts.map((product) => (
          <Variant
            theme={theme}
            img={`${product}3`}
            title={t(`products.${product}`)}
            product={`${product}`}
            pxContext={3}
            pb={3}
            fontSize={"12px"}
            borderRadius={"13px"}
            lg={elementsOnRow}
            md={elementsOnRow}
            sm={elementsOnRow}
            xs={elementsOnRow}
            handleClose={handleClose}
          />
        ))}
    </Grid>
  );
};

function NavbarMessagesDropdown() {
  const ref = useRef(null);
  const productImg = useRef(null);
  const { t } = useTranslation();
  const { info } = useStorage();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title={t("toolbar.available_products")}>
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator>
            <IconMenu>
              <ImgMenu
                ref={productImg}
                src={`/static/img/screenshots/${info.currentProduct}3.svg`}
                //attemp to load again, just in case :)
                onError={(e) => {
                  productImg.current.src =
                    "/static/img/screenshots/no_product.png";
                  // setTimeout(() => {
                  //   productImg.current.src = `/static/img/screenshots/${info.currentProduct}3.svg`;
                  // }, 200);
                }}
              />
            </IconMenu>
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <MessageHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {t("toolbar.available_products")}
          </Typography>
        </MessageHeader>
        <React.Fragment>
          <div style={{ width: "90%", margin: "20px auto" }}>
            <AvailableProducts handleClose={handleClose} />
          </div>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarMessagesDropdown;
