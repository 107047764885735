/*eslint-disable*/
import React from "react";
import {
    Grid,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    Typography,
    IconButton,
} from "@mui/material";
import { red } from "@mui/material/colors";
import Edit from '@mui/icons-material/Edit';
import Delete from "@mui/icons-material/Delete";

import usersMockData from './usersMockData';
import styled from "@emotion/styled";


const UserCardContainer = styled(Card)`
  display: flex;
  height: 100%;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Image = styled(CardMedia)`
  width: 100px;
  height: 100px;
`;

const IconButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
`;

const UserCard = ({ user }) => {
    const { name, surname, role, email, phone, lastAccess } = user;

    return (
        <Grid item xs={12} sm={6} md={4}>
            <UserCardContainer>
                <ImageContainer>
                    <Image
                        image="https://via.placeholder.com/150"
                        title={`${name} ${surname}`}
                    />
                    <IconButtonContainer>
                        <IconButton>
                            <Edit />
                        </IconButton>
                        <IconButton>
                            <Delete style={{ color: red[500] }} />
                        </IconButton>
                    </IconButtonContainer>
                </ImageContainer>
                <ContentContainer>
                    <CardContent>
                        <CardHeader
                            title={`${name} ${surname}`}
                            subheader={`Role: ${role}`}
                        />
                        <Typography variant="body2" color="textSecondary" component="p">
                            <strong>Email:</strong> {email}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            <strong>Phone:</strong> {phone}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            <strong>Last access:</strong> {lastAccess}
                        </Typography>
                    </CardContent>
                </ContentContainer>
            </UserCardContainer>
        </Grid>
    );
};



const UserProfileView = () => {

    return (
        <Grid container spacing={3}>
            {usersMockData.map((user, index) => (
                <UserCard key={index} user={user} />
            ))}
        </Grid>
    )
}



export default UserProfileView;