/* eslint-disable */
import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';


const DateFilterSelector = ({ title, filterName, defaultSelection, setDateSelected }) => {
  const [value, setValue] = React.useState(defaultSelection || new Date());
  const notifyChanges = () => {
    setDateSelected({ [filterName]: value });
  }
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
    >
      <p style={{fontWeight:100}}>{title}</p>
      <div style={{marginBottom: '1em', borderBottom:'1px solid rgba(224, 224, 224, 1)', paddingBottom: '1em'}}>
        <p style={{fontWeight:100, fontSize: '12px', margin: '0 0.5em 0'}}>Desde</p>
        <DatePicker
          disableFuture={true}
          value={value}
          onChange={(newValue) => {
            setValue(newValue.toISOString());
            notifyChanges();
          }}

          renderInput={(params) => <TextField style={{width: '100%'}} {...params} />}
          mask="__/__/____"
          openTo="day"
          views={['year', 'month', 'day']}
        />
      </div>
    </LocalizationProvider>
  );
}


export default DateFilterSelector;