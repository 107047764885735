/* eslint-disable */
class Storage {
  constructor(name) {
    this.name = name;
    this.store = {};
  }

  checklocalStorageAvailable() {
    try {
      // Esta mierda hace saltar el catch porque no deja acceder.
      /*       if (!window.localStorage) {
              return false;
            } */
      const localStorageIsGettable = JSON.parse(localStorage.getItem(this.name)) !== null;
      return localStorageIsGettable;
    } catch (error) {
      console.log('entrando al catch de session')
      return false;
    }
  }

  getItem(key, defaultValue) {
    this.store = this.checklocalStorageAvailable() ? JSON.parse(localStorage.getItem(this.name)) : {};
    const item = this.store[key];
    if (item === undefined || item === null) {
      return defaultValue;
    }
    return item;
  }

  setItem(key, value) {
    console.log(`setItem key: ${key} - value: ${value}`)
    console.log("this.checklocalStorageAvailable(): ", this.checklocalStorageAvailable())
    this.store[key] = value;
    localStorage.setItem(this.name, JSON.stringify(this.store));
  }

  clear() {
    if (this.checklocalStorageAvailable()) window.localStorage.removeItem(this.name);
  }
}

export default Storage;
