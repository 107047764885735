import { BookOpen, List, Users, CreditCard } from "react-feather";

const dashboardItems = (t) => {
  // ¿La versión iria a pincho o tendría que venir de algún endpoint?
  const docsSection = [
    {
      href: "/documentation/welcome",
      icon: BookOpen,
      title: t("dashboard_support.documentation"),
    },
    {
      href: "/changelog",
      icon: List,
      title: t("dashboard_support.changelog"),
      badge: "v4.2.0",
    },
  ];

  const administrationItems = [
    {
      href: "/administration/invoices",
      icon: CreditCard,
      title: t("menu.administration.invoices.title"),
      children: [
        {
          href: "/administration/invoices/list",
          title: t("menu.administration.invoices.list"),
        },
        {
          href: "/administration/invoices/detail",
          title: t("menu.administration.invoices.detail"),
        },
      ],
    },
    {
      href: "/administration/profile/users",
      icon: Users,
      title: t("menu.administration.users"),
    },
  ];

  const navItems = [
    {
      title: t("dashboard_support.title"),
      pages: docsSection,
    },
    {
      title: t("menu.administration.title"),
      pages: administrationItems,
    },
  ];
  return navItems;
};

export default dashboardItems;
