import React, { useReducer, useContext, useEffect, useState } from "react";
import Storage from "utils/storage";

const storage = new Storage("portalv3");

const reducer = (info, newInfo) => {
  return { ...info, ...newInfo };
};

const defaultInfo = {
  currentProduct: null,
  user: null,
};

const InfoContext = React.createContext();

const StorageProvider = ({ children }) => {
  const name = "demoInfo";
  const initialInfo = storage.getItem(name) || defaultInfo;

  const [info, setInfo] = useReducer(reducer, initialInfo);

  useEffect(() => {
    const savedInfo = storage.getItem(name);
    if (savedInfo) {
      setInfo(savedInfo);
    }
  }, []);

  useEffect(() => {
    storage.setItem(name, info);
  }, [info]);

  return (
    <InfoContext.Provider value={{ info, setInfo }}>
      {children}
    </InfoContext.Provider>
  );
};

const useStorage = () => useContext(InfoContext);
const clearStorage = () => storage.clear();

export { useStorage, StorageProvider, clearStorage };
