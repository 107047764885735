import { CreditCard, Settings, Image } from "react-feather";

const dashboardItems = (t) => {
  const pagesSection = [
    {
      href: "/id/verifications",
      icon: Image,
      title: t("id.menu.id.verifications"),
    },
    {
      href: "/id/settings",
      icon: Settings,
      title: t("id.menu.id.settings"),
    },
  ];

  const navItems = [
    {
      title: t("id.menu.id.title"),
      pages: pagesSection,
    },
  ];

  return navItems;
};

export default dashboardItems;
